import Vue from 'vue';
import VueRouter from 'vue-router';
import Einkaufsliste from '../views/Einkaufsliste.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    name: 'Einkaufsliste',
    component: Einkaufsliste,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
