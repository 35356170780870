<template>
  <div class="home">
    <div class="lade" v-if="lade"><div class="spinner">C</div>Lade ... </div>
    <div class="symbolleiste">
      <button class="button" @click="trySynchronisieren()">Aktualisieren</button>
    </div>
    <h1>Einkaufsliste</h1>
    <input type = "text" v-model="neuesElement" @keyup.enter="addElement()" />
    <button class="button" @click="addElement()">Ok</button>
    <div class="elemente">
        <div
          v-for="(element, idx) in elemente" :key="idx"
          class="element"
        >
          <span
            :class="{ 'durchgestrichen': element.erledigt}"
            @click="haekchenElement(idx)"
          >
            {{ element.wert }}
          </span>
          <input class="inputAnzahl" type = "text" v-model="element.anzahl"
            @change="editElement(idx, 'anzahl', element.anzahl)"
          >
          <button @click="delElement(idx)">X</button>
        </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'Home',
  components: {
  },
  data: () => ({
    neuesElement: '',
    elemente: [],
    elementeZuLoeschen: [],
    elementeZuBearbeiten: [],
    lade: false,
    ajaxRdy: false,
  }),
  methods: {
    addElement() {
      if (this.neuesElement !== '') {
        this.elemente.push({
          id: '',
          wert: this.neuesElement,
          erledigt: false,
          anzahl: 1,
        });
        this.neuesElement = '';
        this.trySynchronisieren();
      }
    },
    delElement(idx) {
      this.elementeZuLoeschen.push(this.elemente[idx]);
      this.elemente.splice(idx, 1);
      this.trySynchronisieren();
    },
    editElement(idx, typ, wert) {
      this.elementeZuBearbeiten.push({
        id: this.elemente[idx].id,
        typ,
        wert,
      });
      this.trySynchronisieren();
    },
    haekchenElement(idx) {
      if (
        this.elemente[idx].erledigt === true
      ) {
        this.elemente[idx].erledigt = false;
      } else {
        this.elemente[idx].erledigt = true;
      }
      this.editElement(idx, 'erledigt', this.elemente[idx].erledigt);
    },
    async trySynchronisieren() {
      this.ajaxRdy = false;
      setTimeout(() => {
        if (!this.ajaxRdy) {
          this.lade = true;
        } else {
          this.ajaxRdy = false;
        }
      }, 200);
      $.ajax({
        url: 'https://einkaufsliste.mbastijans.de/api/',
        type: 'post',
        data: {
          elemente: JSON.stringify(this.elemente),
          elementezuloeschen: JSON.stringify(this.elementeZuLoeschen),
          elementezubearbeiten: JSON.stringify(this.elementeZuBearbeiten),
        },
        success: (response) => {
          this.ajaxRdy = true;
          this.elemente = response.elemente;
          this.elementeZuLoeschen = [];
          this.elementeZuBearbeiten = [];
          console.log(response);
          console.log(this.elemente);
          this.lade = false;
        },
      });
    },
  },
  created() {
    this.trySynchronisieren();
  },
};
</script>

<style scoped>
  .lade {
    position: absolute; left: 0px; top: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lade .spinner {
    margin: 5px;
    animation-name: rotation;
    animation-duration:0.5s;
    animation-iteration-count:infinite;
    animation-timing-function:linear;
  }
  @keyframes rotation{
    0%{transform: rotate(0deg);}
    100%{transform: rotate(360deg);}
  }
  span.durchgestrichen {
    text-decoration: line-through;
  }

  .button {
  background-color: rgb(95, 95, 95);
  border: none;
  color: white;
  padding: 8px 18px;
  margin: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 10px;
  transition-duration: 0.4s;
}

.button:hover {
  background-color: rgb(95, 95, 95);
  border: none;
  color: white;
  padding: 12px 26px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  box-shadow: 0 20px 32px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.symbolleiste {
  width: 100%;
  background-color: rgba(95, 95, 95, 0.76);
}

.inputAnzahl {
  width: 70px;
}

</style>
